.App {
  text-align: center;
}

#everfin-mockup-both {
  margin-top: 1rem;
  width: 90%;
}

.full-width {
  width: 100%;
}

.align-center {
  text-align: center;
}

hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 75%;
  border-color: #f0f0f0 !important;
}

.float-right {
  float: right;
}

.display-none {
  display: none;
}

.greyed-out {
  color: #979797;
}

.dark-grey {
  color: #7D7D7D;
}

.everfin-green-text {
  color: #306D5C !important;
}

.navbar * {
  font-weight: 650;
  font-size: 15px;
}

.navbar a {
  color: #C25982;
}

.navbar a:hover {
  color: #C25982;
  cursor: pointer;
}

.navbar .scroll-link a:hover {
  color: #C25982 !important;
}

.navbar img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 150px;
}

.Toastify__toast--success {
  background: #306D5C;
}

.Toastify__toast--error {
  background: #6D3030;
}

#signup-btn {
  color: white;
  /* background-color: #152340; */
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  margin-left: 1rem;
}

a.btn {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

a.btn:hover {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

a.btn.alt {
  color: #306D5C;
  border: none;
  background: white;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

a.btn.alt:hover {
  color: #306D5C;
  border: none;
  background: white;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

button.btn {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

button.btn:hover {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

#hero-section {
  /* background-color: #152340; */
  min-height: 100vh;
}

#hero-container {
  text-align: left;
}

#hero-text-1 {
  margin-top: 15%;
}

#hero-text-1 div {
  margin-bottom: 3rem;
}

#hero-text-2 {
  margin-top: 5%;
}

h1 {
  font-size: 40px;
  font-weight: bold;
}

p {
  font-size: 30px;
  font-weight: 300;
}

img.dashboard-img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 950px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
  /* max-width: 896px;
  max-height: 504px; */
}

img.features-img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 528;
  /* max-width: 896px;
  max-height: 504px; */
}

.has-shadow {
  box-shadow: 0 12px 32px rgba(20, 35, 64, 0.24);
}

#curious-section {
  background-color: #306D5C;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  text-align: left;
  color: white;
}

#curious-section form{
  margin-top: 2rem;
}

#curious-section .form-control {
  border-radius: 40px;
  padding: 28px 40px;
  outline: none;
  border: 1px solid white;
}

#curious-section button.btn {
  border: 1px solid white;
}

#meet-the-team-section {
  min-height: 100vh;
  text-align: left;
}

.card {
  height: 100%;
  border-radius: 20px;
  border: none;
  box-shadow: 10px 20px 30px 0 rgba(0, 0, 0, 0.25);
}

.card-img, .card-img-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px
}

.card p {
  font-size: 16px;
}

.card p.card-title {
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.card p.card-subtitle {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #306D5C;
}

.card p.card-text {
  font-weight: normal;
  font-size: 15px;
}

#contact-us-section .container {
  min-height: 100vh;
  text-align: left;
  padding-top: 3rem;
}

#contact-us-section .everfin-green-text {
  font-weight: bold;
}

#contact-us-section a:hover {
  text-decoration: none;
}

#features-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

#customers-section {
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #152340; */
  /* color: white; */
}

h2.section-header {
  color: #575757;
  font-weight: 650 !important;
}

#pricing-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.card-deck .card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#steps-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.divtest {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 250px;*/
  width: 250px !important;
  background-color: #00008b;
  color: #fff;
  
  font-size: 4em;
}

#cta-section {
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #39b54a; */
}

/* #cta-section .btn {
  background: linear-gradient(135deg, white, white);
  color: #39b54a;
  font-weight: bold;
}

#cta-section .btn:hover {
  background: linear-gradient(135deg, white, white);
  color: #39b54a;
} */

.form-control {
  /* background-color: white; */
  /* border: 2px solid white; */
  border-radius: 0px;
  /* color: white; */
  outline: none;
  /* box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17); */
  border: none;
  border-bottom: 1px #306D5C solid;
}

textarea.form-control {
  border: 1px #306D5C solid;
  border-radius: 10px;
}

/* #cta-section .form-control::placeholder {
  color: white;
} */

#contact-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

#contact-us-section input {
  /* background-color: white; */
  /* border: 2px solid white; */
  border-radius: 0px;
  /* color: white; */
  outline: none;
  /* box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17); */
  border: none;
  border-bottom: 1px #306D5C solid;
  width: 50%;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 5px 10px;
}

#contact-us-section textarea {
  border: 1px #306D5C solid;
  border-radius: 10px;
  width: 100%;
  padding: 10px 10px;
}

#contact-us-section textarea:focus {
  outline: none !important;
}

#contact-us-section input[type="submit" i] {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
  width: 25%;
  float: right;
}

form {
  justify-content: center;
}

.btn-group {
  margin-bottom: 2rem;
}

#contact-info a {
  background-color: #39b54a;
  border-color: #39b54a;
}

footer {
  padding-top: 180px;
  padding-left: 100px;
  padding-right: 100px;
  color: #979797;
  text-align: left;
}

footer .container {
  padding-left: 0;
  padding-right: 0;
}

footer p {
  margin-bottom: 0;
}

footer svg {
  margin-right: 1rem;
}

footer a {
  cursor: pointer;
  color: #7D7D7D;
}

footer a:hover {
  color: #7D7D7D;
  text-decoration: none;
}

footer h5 {
  font-weight: 650;
}

.main-subsection {
  padding-bottom: 40px;
}

.split-subsection {
  text-align: left;
  padding-top: 40px;
  padding-bottom: 40px;
}

footer svg {
  font-size: 150% !important;
}

footer img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 150px;
}

#contact-section svg path {
  fill: #8cc63f;
}

.footer-socials {
  margin: 10px 0;
}

.footer-socials a {
  color: #979797;
}

.footer-socials a:hover {
  color: #979797;
}

/* Pricing section */
#pricing-section .card {
  border: solid 2px transparent;
}

#pricing-section .card:hover {
  border: solid 2px #8cc63f;
}

#pricing-section a {
  color: #8cc63f;
}

#pricing-section a:hover {
  text-decoration: none;
}

.coming-soon-section {
  text-align: center !important;
}

.coming-soon-section a {
  color: #8cc63f;
}

.coming-soon-section a:hover {
  color: #8cc63f;
}

.coming-soon-section svg {
  margin-right: 1rem;
  font-size: 200% !important;
}

.navbar-light .navbar-nav a.nav-link {
  color: #306D5C;
}

.navbar-light .navbar-nav a.nav-link:hover {
  color: #306D5C;
}

.navbar-light .navbar-nav a.nav-link:focus {
  color: #306D5C !important;
}

#landing-page #hero-section h3 {
  font-weight: 650;
  margin-bottom: 1rem;
}

#quick-explanation-icons-section {
  padding-top: 60px;
  padding-bottom: 40px;
}

#quick-explanation-icons-section p,h5{
  text-align: left;
}

.align-left {
  text-align: left;
}

form button {
  padding: 8px 20px;
}

#cta-section .section-header {
  margin-top: 5rem;
}

#fruit-basket {
  max-width: 370px;
}

#copyright {
  margin-top: 3rem;
}

#how-it-works-section h1{
  color: #A9CB59;
  font-weight: 650;
}

#how-it-works-section p{
  text-align:left;
  color: #7D7D7D;
  font-weight: 650;
}

#how-it-works-section .section-header{
  margin-bottom: 2rem;
}

.desktop-product-showcase {
  max-width: 90%;
}

.desktop-how-it-works {
  margin-top: 0.8rem;
  max-width: 88%;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17);
  border-radius: 45px;
}

.mobile-how-it-works {
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17);
  border-radius: 45px;
}

@media (min-width: 768px) {
  #team-cards-row {
    margin-left: -49px;
    margin-right: -49px;
  }

  .navbar {
    padding: 1rem 2rem;
  }

  .hide-on-desktop {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  h1 {
    font-size: 30px;
  }
  
  p {
    font-size: 16px;
  }

  #meet-the-team-section {
    min-height: 0px;
  }

  #contact-us-section {
    min-height: 0px;
  }

  #contact-us-section .container{
    min-height: 0px;
    padding-bottom: 2rem;
  }

  #hero-text-1 div {
    margin-bottom: 2rem;
  }

  #hero-text-2 {
    overflow: hidden;
    padding-top: 22.4rem;
  }


  #hero-text-2 img{
    width: 90%;
    position: absolute;
    bottom: 190px;
    left: 115px;
  }

  button.btn {
    font-size: 12px;
  }
  
  button.btn:hover {
    font-size: 12px;
  }

  #everfin-mockup-both {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .card {
    box-shadow: none;
    border: #306D5C25 1px solid;
    margin-right: 1.5rem;
  }

  .react-multi-carousel-list {
    padding-left: 0px;
  }

  #curious-section .form-control{
    padding: 24px 20px;
  }

  #curious-section button.btn {
    padding: 15px 10px;
  }

  #contact-us-section input[type="submit" i] {
    width: 50%;
  }
}